import { handleQueryResolve } from '../utils';

export default function(name){

    if(typeof name === 'string'){
      return this.query(`
        SELECT
          patient.Id as PatientID,
          patient.FirstName,
          patient.LastName,
          "patient"."DateOfBirth" as DOB
        FROM
          DBA.Patient as patient
        WHERE
          patient.FirstName LIKE @name OR
          patient.LastName LIKE @name
        ORDER BY
          patient.FirstName, patient.LastName, DOB
        `, [{
          param: 'name',
          type: 'sql.VarChar(50)',
          value: '%' + name + '%' // to support like
      }]).then(handleQueryResolve);
    }
    if(typeof name === 'object'){

      let attrs = [];
      let where = [];
      if(name.firstName){
        attrs.push({
          param: 'firstName',
          type: 'sql.VarChar(50)',
          value: '%' + name.firstName + '%' // to support like
        });

        where.push('patient.FirstName LIKE @firstName');
      }

      if (name.lastName) {
        attrs.push({
          param: 'lastName',
          type: 'sql.VarChar(50)',
          value: '%' + name.lastName + '%' // to support like
        });

        where.push('patient.LastName LIKE @lastName');
      }

      return this.query(`
        SELECT
          patient.Id as PatientID,
          patient.FirstName,
          patient.LastName,
          "patient"."DateOfBirth" as DOB
        FROM
          DBA.Patient as patient
        WHERE
          ${where.join(' AND ')}
        ORDER BY
          patient.FirstName, patient.LastName, DOB
        `, attrs).then(handleQueryResolve);
    }
}